import React from "react";

const Parallax = () => {
  return (
    <div className="container-fluid p-0">
      <div className="parallax"></div>
      <div className="parallax-mobile"></div>
    </div>
  );
};

export default Parallax;
