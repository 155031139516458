import React from "react";

const Experience = () => {
  return (
    <div id="experience" className="experience">
      <div className="d-flex justify-content-center my-5">
        <h1>experience</h1>
      </div>
      <div className="container experience-wrapper">
        <div className="timeline-block timeline-block-left">
          <div className="marker"></div>
          <div className="timeline-content">
            <h3>SQL</h3>
            <p>
              Query and report writing using SQL, T-SQL, Stored Procedures and
              MS Reporting Services
            </p>
          </div>
        </div>
        {/* */}
        <div className="timeline-block timeline-block-right">
          <div className="marker"></div>
          <div className="timeline-content">
            <h3>Visual Studio</h3>
            <p>
              Developed various client/server application within the Visual
              Studio development environment, specifically MVC development.{" "}
            </p>
          </div>
        </div>
        {/* */}
        <div className="timeline-block timeline-block-left">
          <div className="marker"></div>
          <div className="timeline-content">
            <h3>SharePoint Workfow</h3>
            <p>
              Developed SharePoint workflow applications while integrating
              Nintex for SharePoint Web Services.{" "}
            </p>
          </div>
        </div>
        {/* */}
        <div className="timeline-block timeline-block-right">
          <div className="marker"></div>
          <div className="timeline-content">
            <h3>WordPress</h3>
            <p>
              WordPress websites development & 3rd party theme customization via CSS, Javascript,
              PHP, and Elementor Pro.
            </p>
          </div>
        </div>
        {/* */}
        <div className="timeline-block timeline-block-left">
          <div className="marker"></div>
          <div className="timeline-content">
            <h3>REACT & Node.js</h3>
            <p>
              Website development using React and Node.js. This is currently my
              preferred  environment because React is a widely used, readily
              available open source library that facilitates the development & customization
              of applications.
            </p>
          </div>
        </div>
        <div className="timeline-block timeline-block-right">
          <div className="marker"></div>
          <div className="timeline-content">
            <h3>Web3</h3>
            <p>
              I am currently in the process of learning and developing
              applications layered on the Web3 technology stack.
              Although I am no expert, I am more than willing to share what I've
              learned so far.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
};

export default Experience;
