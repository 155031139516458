import React from "react";
import author from "../images/MariBlur.jpg";

const AboutMe = () => {
  return (
    <div id="about" className="container px-sm-5 my-5 pt-2 pb-2">
      <div className="row">
        <div className="col-lg-4 col-xm-12">
          <div className="photo-wrap text-center">
            <img
              className="img-fluid profile-img"
              src={author}
              alt="author..."
            />
          </div>
        </div>
        <div className="col-lg-8 col-xm-12">
          <h1 className="about-heading">about me</h1>
          <p>
            I've worked in the IT field as a programmer analyst and software
            engineer for over 20 years. Much of my experience includes working
            with Microsoft Visual Studio, Microsoft SQL Server, Microsoft
            Reporting Services, and Nintex for SharePoint. My focus is
            on website development, application development, and Web3. My
            services include website development and application development for
            projects that demand a high level of customization beyond the
            capabilities of drag-and-drop services.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutMe;
