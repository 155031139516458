import React, { useState } from "react";
import logoLight from "../images/logo_light_80.png";
import logoDark from "../images/logo_dark_80.png";
//React Font Awesome Import
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faBorderAll } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-scroll";

const Navbar = () => {
  const [navbar, setNavbar] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 90) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeBackground);
  return (
    <nav className={
      navbar
        ? "navbar active navbar-expand-lg fixed-top"
        : "navbar  navbar-expand-lg fixed-top"
    }>
    <div className="container-fluid">
      <Link
        smooth={true}
        to="home"
        offset={-100}
        className="navbar-brand"
        href="#"
      >
        <img
          src={navbar ? logoDark : logoLight}
          alt="logo"
          className="navbar-brand"
        />
      </Link>
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <FontAwesomeIcon
          icon={faBars}
          style={navbar ? { color: "#000" } : { color: "#fff" }}
        />
      </button>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
          <li className="nav-item active">
            <Link
              smooth={true}
              to="home"
              offset={-100}
              className="nav-link active"
              aria-current="page"
              href="#"
              data-bs-toggle="collapse" 
              data-bs-target=".navbar-collapse.show"
            >
              Home
            </Link>
          </li>
          <li className="nav-item">
            <Link
              smooth={true}
              to="about"
              offset={-120}
              className="nav-link active"
              aria-current="page"
              href="#"
              data-bs-toggle="collapse" 
              data-bs-target=".navbar-collapse.show"
            >
              about me
            </Link>
          </li>
          <li className="nav-item">
            <Link
              smooth={true}
              to="services"
              offset={-100}
              className="nav-link active"
              aria-current="page"
              href="#"
              data-bs-toggle="collapse" 
              data-bs-target=".navbar-collapse.show"
            >
              Services
            </Link>
          </li>
          <li className="nav-item">
            <Link
              smooth={true}
              to="experience"
              offset={-100}
              className="nav-link active"
              aria-current="page"
              href="#"
              data-bs-toggle="collapse" 
              data-bs-target=".navbar-collapse.show"
            >
              experience
            </Link>
          </li>           
         
            
        </ul>
        
      </div>
    </div>
  </nav>
  );
};

export default Navbar;
