import React from "react";
import Typed from "react-typed";
import {Link} from "react-scroll";

const Header = () => {
  return (
    <div id="home" className="header-wrapper">
      <div className="main-info">
        <span className="landing-header display-1 my-5">
          Web & App Development
        </span>
        <Typed
          className="typed-text"
          strings={["Web Design", "Web Development", "App Development", "Web3"]}
          typeSpeed={40}
          backSpeed={60}
        />
        <Link smooth={true} to="contacts" offset={-100} href="#" className="btn-main-offer">
          Contact me
        </Link>
      </div>
    </div>
  );
};

export default Header;
