import react,{Fragment} from "react";
import "./App.css";
import Particles from "react-particles-js";
import Navbar from "./components/Navbar";
import Header from "./components/Header";
import AboutMe from "./components/AboutMe";
import Services from "./components/Services";
import Experience from "./components/Experience";
import Contacts from "./components/Contacts";
import Parallax from "./components/Parallax";
import Footer from "./components/Footer";



function App() {
  return (
    <Fragment>
      <Particles
      className="particles-canvas"
       params={{
        particles:{
          number:{
            value:15,
            density:{
              enable:true,
              value_area:900
            }
          },
          shape: {
            type:"circle",
            stroke: {
              width:2,
              color:"#bec2cb"
            }
          }
        }
       }}
      />
      <Navbar />
      <Header />
      <AboutMe/>
      <Services/>
      <Experience/>
   
      <Parallax/>
      <Footer/>
    </Fragment>
  );
}

export default App;
