import React from "react";

/*import { faBitcoin, faFacebookF, faGoogle, faEthereum} from "@fortawesome/free-brands-svg-icons";*/

import { faBitcoin} from "@fortawesome/free-brands-svg-icons";
import { faDesktop, faCode, faNetworkWired} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'




const Services = () => {
    return (
      <div id="services" className="services">
          <h1 className="py-5">My Services</h1>
              <div className="container">
                  <div className="row">
                      <div className="col-xl-3 col-lg-6">
                          <div className="box">
                              <div className="circle my-4"><FontAwesomeIcon className="icon" icon={faDesktop} size="2x"/></div>
                              <h3>Web Design</h3>
                              <p>I approach each project individually and always focus on the result.</p>
                          </div>
                      </div>
                      {/* */}
                      <div className="col-xl-3 col-lg-6">
                      <div className="box">
                      <div className="circle my-4"><FontAwesomeIcon className="icon" icon={faNetworkWired} size="2x"/></div>
                              <h3>Web Development</h3>
                              <p>Your website will be built with new proven technologies.</p>
                          </div>
                      </div>
                        {/* */}
                      <div className="col-xl-3 col-lg-6">
                      <div className="box">
                      <div className="circle my-4"><FontAwesomeIcon className="icon" icon={faCode} size="2x"/></div>
                              <h3>App Development</h3>
                              <p>Customize your website to meet your business needs. Make your website mobile.</p>
                          </div>
                      </div>
                        {/* */}
                      <div className="col-xl-3 col-lg-6">
                      <div className="box">
                      <div className="circle my-4"><FontAwesomeIcon className="icon" icon={faBitcoin} size="2x"/></div>
                              <h3>Web3</h3>
                              <p>Learn about Web3 and find out how blockchain can benefit your business.</p>
                          </div>
                      </div>
                  </div>
              </div>
      </div>
    )
}

export default Services
